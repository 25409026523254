<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
            <selectComp
              :hval="status"
              :data="payStatusList"
              @change="statusChange"
              placeholder="状态"
            />
            <selectComp
              placeholder="合作类型"
              :data="cooperationModeList"
              :hval="cooperationMode"
              @change="cooperationModeChange"
            />
            <selectComp
              prop="billingType"
              placeholder="开票类型"
              :data="billingTypeList"
              :hval="billingType"
              @change="billingTypeChange"
            />
            <selectComp
              prop="settlementCycle"
              placeholder="结算周期"
              :data="settlementCycleList"
              :hval="settlementCycle"
              @change="settlementCycleChange"
            />
            <inputComp
              placeholder="服务站名称"
              :hval="serviceStationName"
              @blur="serviceStationNameBlur"
            />
            <dateComp
              :hval="date"
              startPlaceholder="付款开始日期"
              endPlaceholder="付款结束日期"
              @change="dateChange"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchAudit"
              v-permission="'批量付款'"
              >批量付款</btnComp
            >
            <btnComp @click="exportData" v-permission="'导出列表'">导出列表</btnComp>
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @select="select"
          isShowCheckBox
          @selectAll="selectAll"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="payTime" label="付款日期" width="180">
            <template v-slot="scope">
              {{ handleDate(scope.row.payTime) || '-' }}
            </template>
          </el-table-column>
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="detail(scope.row)" v-permission="'明细'">明细</btnComp>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="hintSure">
      <p>确定批量付款吗？</p>
    </dialogComp>
  </div>
</template>

<script>
import { statPayBillList, statPayBillUpdateStatus, statPayBillExport } from '@/api'
import { formatDate } from '@/utils/common'
import constList from '@/mixin/constList'
export default {
  mixins: [constList],
  data () {
    return {
      loading: false,
      hintShow: false,
      selectIds: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
      code: '',
      status: '',
      cooperationMode: '',
      billingType: '',
      settlementCycle: '',
      serviceStationName: '',
      date: [],
      tableData: [],
      theadName: [
        { prop: 'statusName', label: '状态' },
        { prop: 'serviceStationName', label: '服务站' },
        { prop: 'cooperationModeName', label: '合作类型' },
        { prop: 'billingTypeName', label: '开票类型' },
        { prop: 'settlementCycleName', label: '结算周期' },
        { prop: 'price', label: '应付金额' },
        { prop: 'tax', label: '税点' },
        { prop: 'paidIn', label: '实付金额' },
        { prop: 'bank', label: '付款银行' },
        { prop: 'branch', label: '开户支行' },
        { prop: 'cardNumber', label: '账号' },
        { slot: 'payTime' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        code: this.code,
        status: this.status,
        cooperationMode: this.cooperationMode,
        billingType: this.billingType,
        settlementCycle: this.settlementCycle,
        serviceStationName: this.serviceStationName,
        startPayTime: this.startPayTime,
        endPayTime: this.endPayTime
      }
      this.loading = true
      statPayBillList(this.params).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    // 导出
    exportData () {
      statPayBillExport(this.params)
      this.$message({
        message: '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    // 处理时间
    handleDate (date) {
      return date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : '-'
    },
    codeBlur (val) {
      this.code = val
    },
    statusChange (val) {
      this.status = val
    },
    cooperationModeChange (val) {
      this.cooperationMode = val
    },
    billingTypeChange (val) {
      this.billingType = val
    },
    settlementCycleChange (val) {
      this.settlementCycle = val
    },
    serviceStationNameBlur (val) {
      this.serviceStationName = val
    },
    dateChange (startPayTime, endPayTime) {
      this.startPayTime = startPayTime
      this.endPayTime = endPayTime
    },
    batchAudit () {
      this.hintShow = true
    },
    hintSure () {
      const params = {
        idList: this.selectIds
      }
      statPayBillUpdateStatus(params).then(res => {
        if (res.code === '1') {
          this.$message.success('审核成功')
          this.close()
        }
      })
    },
    // 明细
    detail (row) {
      this.$router.push({
        name: 'stat-pay-detail',
        query: {
          id: row.id
        }
      })
    },
    close () {
      this.selectIds = []
      this.hintShow = false
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.code = ''
      this.status = ''
      this.cooperationMode = ''
      this.billingType = ''
      this.settlementCycle = ''
      this.serviceStationName = ''
      this.startPayTime = ''
      this.endPayTime = ''
      this.date = []
      this.getList()
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
